<template>
  <div class="container mb-3">
    <div class="my-3 press-back" @click="$router.push('/booking')">
      <font-awesome-icon
        :icon="['fas', 'chevron-left']"
        class="mr-2"
      />ประวัติการจอง
    </div>
    <div class="card">
      <div class="card-body">
        <!-- <pre>{{ bookingDetail }}</pre> -->
        <div class="booking-header">{{ bookingDetail.booking_name }}</div>
        <div><b>Booking ID</b></div>
        <div>
          <small>{{ bookingDetail.prefix_id }}</small>
        </div>
        <div class="mt-2"><b>ชื่อ-นามสกุล</b></div>
        <div>
          <small
            >{{ bookingDetail.firstname_answer }}
            {{ bookingDetail.lastname_answer }} ({{
              bookingDetail.username
            }})</small
          >
        </div>
        <div class="mt-2"><b>สถานะ</b></div>
        <div>
          <small>{{ bookingDetail.status_name }}</small>
        </div>
        <div class="mt-2"><b>วันและเวลาที่นัดหมาย</b></div>
        <div>
          <small>{{ bookingDetail.booking_date | momentFormat() }}</small>
        </div>
        <div></div>
        <div v-if="bookingDetail.description.length > 0">
          <div v-for="desc of bookingDetail.description" :key="desc">
            <div class="mt-2">
              <b>{{ desc.name }}</b>
            </div>
            <div v-if="desc.field_type_id == 8">
              <img
                :src="desc.value"
                alt="รูปที่ลูกค้าอัพโหลด"
                class="upload-booking-img"
              />
            </div>
            <div v-else>
              <small>{{ desc.value }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment");
export default {
  data() {
    return { bookingDetail: {} };
  },
  created() {
    this.getDetail();
  },
  filters: {
    momentFormat(val) {
      return moment(val).format("DD/MM/YYYY (HH:mm)");
    }
  },
  methods: {
    async getDetail() {
      const res = await this.$axios(
        `${process.env.VUE_APP_API}/api/v1/Booking/answerdetail/${this.$route.params.id}`
      );

      this.bookingDetail = res.detail;
    }
  }
};
</script>

<style>
.booking-header {
  font-size: var(--text-xl);
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--primary-color);
}
.press-back {
  color: white;
}
.upload-booking-img {
  max-width: 100%;
  max-height: 400px;
}
</style>
